import axios from "axios"
import React, { useState } from "react"
import styled from "styled-components"
import Container from "../components/container"
import Layout from "../components/layout"
import SEO from "../components/seo"

const SC = {}

const Contato = () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })

  const handleServerResponse = (ok, message, form) => {
    setServerState({
      submitting: false,
      status: { ok, message },
    })
    if (ok) {
      form.reset()
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    const form = event.target

    setServerState({ submitting: true })
    axios({
      method: "post",
      data: new FormData(form),
      url: "https://getform.io/f/46fca1ed-c188-46ab-b742-6ef69d92c27d",
    })
      .then(() => handleServerResponse(true, "Obrigado!", form))
      .catch(r => handleServerResponse(false, r.response.data.error, form))
  }

  return (
    <Layout>
      <SEO title="Contato" />
      <SC.Container
        isFlex
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="column"
      >
        <SC.Title>
          Tem alguma dúvida, crítica ou sugestão?
          <br /> Nos mande um “alô”!
        </SC.Title>
        <SC.Form onSubmit={handleSubmit}>
          <SC.InputWrapper>
            <SC.Input
              type="text"
              name="name"
              placeholder="Seu primeiro nome"
              required
            />
          </SC.InputWrapper>
          <SC.InputWrapper>
            <SC.Input
              type="email"
              name="email"
              placeholder="Seu e-mail"
              required
            />
          </SC.InputWrapper>
          <SC.InputWrapper>
            <SC.TextArea
              name="message"
              placeholder="Digite sua mensagem"
              required
            ></SC.TextArea>
          </SC.InputWrapper>
          <SC.Submit type="submit">ENVIAR</SC.Submit>
          {serverState.status && (
            <SC.ServerResponse>{serverState.status.message}</SC.ServerResponse>
          )}
        </SC.Form>
      </SC.Container>
    </Layout>
  )
}

SC.Container = styled(Container)`
  min-height: 100vh;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    padding: 0 25px;
  }
`

SC.Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  text-align: center;
  font-size: 32px;
  margin-top: 80px;
  margin-bottom: 60px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    font-size: 20px;
    margin-top: 100px;
    margin-bottom: 30px;

    br {
      display: none;
    }
  }
`

SC.Form = styled.form`
  margin-bottom: 120px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
  }
`

SC.InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 450px;
  margin: 15px 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
  }
`

SC.Input = styled.input`
  width: 100%;
  height: 50px;
  padding: 0 15px;
  border: 1px solid ${({ theme }) => theme.colors.SECONDARY_PURPLE};
  outline: none;
  border-radius: ${({ theme }) => theme.sizes.DEFAULT_BORDER_RADIUS};
  font-weight: 500;
`

SC.TextArea = styled.textarea`
  height: 200px;
  width: 100%;
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.colors.SECONDARY_PURPLE};
  outline: none;
  border-radius: ${({ theme }) => theme.sizes.DEFAULT_BORDER_RADIUS};
  font-weight: 500;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
  }
`

SC.Submit = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 450px;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.GREEN_1};
  border-radius: ${({ theme }) => theme.sizes.DEFAULT_BORDER_RADIUS};
  border: none;
  outline: none;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.25s ease-in-out;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }
`

SC.ServerResponse = styled.span`
  font-size: 22px;
  text-align: center;
  display: block;
  margin-top: 40px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    font-size: 18px;
  }
`

export default Contato
